import { first } from 'lodash';
import { TransactionClassesStatistics } from "../../../../types/amr-pipeline/models/TransactionClassesStatistics";
import { TransactionsStatistics } from "../../../../types/amr-pipeline/models/TransasctionsStatistics";
import { Currency } from "../../../../types/enums/Currency";
import { DealsPipelineFilters } from "../../../../types/state/PipelineFilterState";
import { amrFormatUtils, formatUtils, moneyUtils, numericUtils } from "../../../../utils";
import { constants } from '../../../../constants/constants';

const currencySign = {
    [Currency.USD]: "$",
    [Currency.EUR]: "€",
};

const defaultVolume = {
    label: "Volume",
    value: 0,
    required: true,
};

const getVolumeColumn = (
    usdBalance: number | undefined,
    eurBalance: number | undefined,
    selectedCurrencies: Currency[],
) => {
    // If both currencies are selected, consider it as 'All' filter
    const selectedCurrency = selectedCurrencies.length > 1
        ? undefined
        : first(selectedCurrencies);

    const selectedValue =
        selectedCurrency === Currency.USD ? usdBalance : eurBalance;

    const presentValue = usdBalance || eurBalance;

    if (!presentValue || (selectedCurrency && !selectedValue)) {
        return defaultVolume;
    }

    if (selectedCurrency && selectedValue) {
        return {
            ...defaultVolume,
            label: `Volume ${currencySign[selectedCurrency]}`,
            value: amrFormatUtils.formatClassesTotalBalance(selectedValue),
        };
    }

    const withBothCurrencies = usdBalance && eurBalance;

    if (withBothCurrencies) {
        return {
            ...defaultVolume,
            label: `Volume ${currencySign.USD + "/" + currencySign.EUR}`,
            value:
                amrFormatUtils.formatClassesTotalBalance(usdBalance) +
                "/" +
                amrFormatUtils.formatClassesTotalBalance(eurBalance),
        };
    }

    return {
        ...defaultVolume,
        label: `Volume ${usdBalance ? currencySign.USD : currencySign.EUR}`,
        value: amrFormatUtils.formatClassesTotalBalance(presentValue),
    };
};

export const getDealsSummaryItems = (summary: TransactionsStatistics, appliedFilters: DealsPipelineFilters) => [
    {
        label: 'Count',
        value: summary.numberOfTransactions,
        required: true,
    },
    getVolumeColumn(
        summary.transactionsBalanceTotalUsd,
        summary.transactionsBalanceTotalEur,
        appliedFilters.currency.filter
            .filter(x => x.selected)
            .map(x => x.value)
    ),
    {
        label: 'Managers',
        value: summary.numberOfManagers,
        required: true,
    },
    {
        label: 'Arrangers',
        value: summary.numberOfArrangers,
        required: true,
    },
    {
        label: 'Trustees',
        value: summary.numberOfTrustees,
        required: true,
    },
    {
        label: 'WAS',
        value: summary.medianWAS && `${formatUtils.formatDecimal(summary.medianWAS)}%`,
        isMedian: true,
    },
    {
        label: 'WARF',
        value: formatUtils.formatDecimal(summary.medianWARF),
        isMedian: true,
    },
    {
        label: 'DS',
        value: formatUtils.formatDecimal(summary.medianDS),
        isMedian: true,
    },
    {
        label: 'PP',
        value: moneyUtils.money(summary.medianPurchasePrice, true),
        isMedian: true,
    }
];

export const getClassesSummaryItems = (summary?: TransactionClassesStatistics) => [
    {
        label: 'C/E',
        value: summary?.medianParSubordination
            ? `${formatUtils.formatDecimal(summary.medianParSubordination)}%`
            : constants.emptyPlaceholder,
        isMedian: true,
    },
    {
        label: 'MVOC',
        value: summary?.medianMVOC ? `${formatUtils.formatDecimal(summary.medianMVOC)}%` : constants.emptyPlaceholder,
        isMedian: true,
    },
    {
        label: 'Margin',
        value: summary?.medianMargin
            ? `${formatUtils.formatDecimal(summary.medianMargin)}%`
            : constants.emptyPlaceholder,
        isMedian: true,
    },
    {
        label: 'DM',
        value: summary?.medianDM ? numericUtils.round(summary.medianDM, 0) : constants.emptyPlaceholder,
        isMedian: true,
    },
];
